import React from 'react';
import { graphql } from 'gatsby';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';

import ContentBlock from '../blocks/ContentBlock';
import { PortraitImage } from '../blocks/PortraitImage';
import { DefaultLayout } from '../layouts/Default';

const UeberUnsPage = ({ data, location }) => {
  const { site, pageData } = data;
  const { opengraph, metadata, content } = pageData;
  const { siteMetadata } = site;

  const paragraphs = content.map((paragraph) => <ContentBlock data={paragraph} key={paragraph.title} />);

  paragraphs.splice(1, 0, <PortraitImage />);

  return (
    <DefaultLayout>
      {paragraphs}
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
    </DefaultLayout>
  );
};

export default UeberUnsPage;

export const UeberUnsPageQuery = graphql`
  query UeberUnsPageQuery {
    site: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    contactData: cockpitRegionKontaktInformationen {
      id
      telephone
      email
    }
    pageData: cockpitRegionUeberUns {
      id
      content {
        title
        published
        text
        align
        columns
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630)
          }
        }
      }
    }
  }
`;
