import React from 'react';
import portraitImage from 'Images/portrait.png';
import clsx from 'clsx';

import * as styles from './PortraitImage.module.css';

export function PortraitImage(props) {
  return (
    <section className={styles.background} {...props}>
      <div className={clsx(styles.container, 'defaultWidth')}>
        <div className={styles.text} style={{ display: 'none' }}>
          <h2>Geschäftsführung und Beratung</h2>
          <p>Christine Baumgartner</p>
        </div>
        {/* <img className={styles.image} src={portraitImage} alt="Portrait Christine Baumgartner" /> */}
      </div>
    </section>
  );
}
